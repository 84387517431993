<template>
  <div class="row">
    <div :class="addedUser.length > 0 ? 'col-lg-6': 'col-lg-12'">
      <div class="card">
        <div class="card-body">
          <form class="form-horizontal">
            <div class="form-body">
              <h3 class="box-title">Disposisi</h3>
              <hr class="m-t-0">
              <template>
                <div class="row">
                  <div class="col-md-12">
                    <label :class="['control-label',  'col-md-12']">
                      Diteruskan Kepada
                      <small style="color:red">*</small>
                    </label>
                  </div>
                </div>
                <div class="row" v-if="user.length > 0">
                  <div class="col-md-8">
                    <div class="form-group">
                      <label :class="['control-label', {'text-right': !isMobile}, 'col-md-1']"></label>
                      <div class="col-md-9 col-sm-9">
                        <div class="checkbox">
                          <input
                            @click="checkAll()"
                            v-model="isCheckAll"
                            id="index"
                            type="checkbox"
                          >
                          <label for="index">Centang Semua</label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6" v-for="(value, index) in user" :key="index">
                    <div class="row">
                      <label :class="['control-label', {'text-right': !isMobile}, 'col-md-1']"></label>
                      <div class="col-md-6 col-sm-12">
                        <div class="checkbox">
                          <input
                            :id="'checkbox-'+index"
                            :value="value"
                            v-model="addedUser"
                            @change="updateCheckall"
                            type="checkbox"
                          >
                          <label :for="'checkbox-'+index">{{value.text}}</label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <hr class="m-t-10">
                <div class="row">
                  <div class="col-md-12">
                    <label :class="['control-label',  'col-md-12']">
                      Perintah Disposisi
                      <small style="color:red">*</small>
                    </label>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6" v-for="(value, index) in disposisiCommand" :key="index">
                    <div class="row">
                      <label :class="['control-label', {'text-right': !isMobile}, 'col-md-1']"></label>
                      <div class="col-md-6 col-sm-12">
                        <div class="checkbox">
                          <input :value="value" v-model="addedCommand" :id="index" type="checkbox">
                          <label :for="index">{{value.text}}</label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </template>
              <template>
                <div class="row m-t-10">
                  <div class="col-md-12">
                    <label :class="['control-label',  'col-md-12']">
                      Catatan Disposisi
                      <small style="color:red">*</small>
                    </label>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12 m-t-10 m-b-10">
                    <wysiwyg v-model="myHTML"/>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6">
                    <div class="form-group">
                      <label :class="['control-label', 'col-md-12']">File Lampiran</label>
                      <div class="col-md-6 col-sm-6 m-l-20">
                        <Upload v-model="payload.photos" is-file name="photo"/>
                      </div>
                    </div>
                  </div>
                </div>
              </template>
            </div>
            <hr>
            <div class="form-actions">
              <div class="row">
                <div class="col-md-12">
                  <div class="row">
                    <div class="col-md-offset-3 col-md-9">
                      <button
                        :disabled="addedUser.length === 0 || disposisiCommand.length === 0"
                        type="button"
                        @click="submit()"
                        class="btn btn-success"
                      >Submit</button>&nbsp;
                      <button type="button" @click="goBack()" class="btn btn-inverse">Cancel</button>
                    </div>
                  </div>
                </div>
                <div class="col-md-6"></div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
    <div class="col-lg-6" v-if="addedUser.length > 0">
      <div class="card">
        <div class="card-body">
          <template class="row no-gutters" v-for="(data, index) in addedUser">
            <div class="col-md-12 m-b-1" :key="index">
              <div class="row">
                <label class="control-label col-md-3">{{data.text}}</label>
                <div class="col-md-4 bd-example-indeterminate">
                  <label class="custom-control custom-radio">
                    <input
                      :id="'radio-'+index"
                      value="1"
                      :name="'radio-'+index"
                      v-model="data.value"
                      type="radio"
                      class="custom-control-input"
                    >
                    <span class="custom-control-label">Pelaksana</span>
                  </label>
                </div>
                <div class="col-md-5">
                  <label class="custom-control custom-radio">
                    <input
                      :id="'radio-'+index"
                      value="2"
                      :name="'radio-'+index"
                      v-model="data.value"
                      type="radio"
                      class="custom-control-input"
                    >
                    <span class="custom-control-label">Sebagai Informasi</span>
                  </label>
                </div>
              </div>
            </div>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Input from "../../../components/input/input";
import Multiselect from "vue-multiselect";
import Upload from "../../../components/input/upload.vue";

export default {
  components: {
    Upload,
    Input,
    Multiselect
  },
  computed: {
    editMode() {
      return !!this.$route.params.id;
    },
    isInvalid() {
      return this.isTouched && this.value.length === 0;
    },
    isMobile() {
      return this.$store.state.isMobile;
    }
  },
  data() {
    return {
      myHTML: "",
      value: [],
      user: [
        { value: "1", text: "HalloA" },
        { value: "2", text: "HalloB" },
        { value: "3", text: "HalloC" }
      ],
      payload: {
        photos: ""
      },
      addedUser: [],
      disposisiCommand: [
        { value: "1", text: "HalloA" },
        { value: "2", text: "HalloB" },
        { value: "3", text: "HalloC" }
      ],
      addedCommand: [],
      isCheckAll: false
    };
  },
  async mounted() {
    this.initialize();
  },

  methods: {
    async initialize() {
      try {
        if (this.editMode) {
          this.$store.dispatch("disposisi/onEdit");
          this.state.firstStep = true;
        } else {
          this.$store.dispatch("disposisi/onAdd");
          this.state.firstStep = true;
        }
      } catch (e) {
        this.handleApiError(e);
        this.error = e.message;
      }
    },
    nextStep() {
      this.state = Object.assign(
        {},
        {
          firstStep: false,
          secondStep: true
        }
      );
    },
    returnStep() {
      this.state = Object.assign(
        {},
        {
          firstStep: true,
          secondStep: false
        }
      );
    },
    checkAll() {
      this.isCheckAll = !this.isCheckAll;
      this.addedUser = [];
      if (this.isCheckAll) {
        for (var key in this.user) {
          this.addedUser.push(this.user[key]);
        }
      }
    },
    updateCheckall() {
      if (this.addedUser.length == this.user.length) {
        this.isCheckAll = true;
      } else {
        this.isCheckAll = false;
      }
    },
    goBack() {
      this.$store.dispatch("disposisi/onCancel");
    },
    submit() {
      this.$validator.validateAll().then(success => {
        if (success) {
          this.isDisabled = false;
        }
      });
    }
  }
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style>
.multiselect__tags {
  border: 1px solid #ced4da !important;
}
.invalid .multiselect__tags {
  border-color: #f04124 !important;
  padding: 5px 40px 0 8px !important;
}
.invalid .typo__label {
  color: #f04124;
  font-size: 0.8125rem;
}
.multiselect {
  color: #54667a !important;
}
.multiselect__select {
  height: 41px !important;
}
</style>

